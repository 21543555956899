/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { AppConfig } from '@tectonic/config';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { appConfig } from './app.config';

AppConfig.setupConfig(appConfig);

const knownErrors = new Set([
  'AbortError',
  'read ECONNRESET',
  'Expected fetch controller',
  'The operation was aborted',
  'There was an error while hydrating',
  'AxiosError: Request failed with status code 500',
  'AxiosError: Request failed with status code 502',
  'The play() request was interrupted by a new load request',
  'This Suspense boundary received an update before it finished hydrating',
  'The play() request was interrupted because the media was removed from the document',
  'Hydration failed because the initial UI does not match what was rendered on the server',
  'TypeError: Load failed',
  `null is not an object (evaluating 'ai.sequence')`,
  'Java object is gone',
  // Raised by shopflo
  'UnhandledRejection: Object captured as promise rejection with keys: body, headers, status',
  '/build/',
  // newly added errors
  '?(manifest)',
  '?(bat)',
  '?(audience)',
  '?(s/0.7.32/clarity)',
  'ReferenceError: UET is not defined',
  'a.L',
  `o(chrome-extension://iohjgamcilhbgmhbnllfolmkmmekfmci/injected-scripts/host-rrweb-network.js)`,
  '$o(chunk-MHG6Z263)',
  `SecurityError: Blocked attempt to use history.replaceState() to change session history URL from about`,
  `Failed to read the 'localStorage' property from 'Window': Access is denied for this document`,
  /localStorage/,
  `Cannot read properties of null (reading 'remove')`,
  /document\.getElementById/,
  'The operation is insecure',
  `Cannot read properties of null (reading 'tagName')`,
]);

Sentry.init({
  sampleRate: 1,
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 0,
  dsn: globalThis.env.SENTRY_DSN,
  ignoreErrors: [...knownErrors],
});

// eslint-disable-next-line no-underscore-dangle
globalThis.___tt___ = {};

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
